.main-content {
  width: inherit;
  box-sizing: border-box;
  padding: 0 4.0567vw 0 4.0625vw;
  //margin-top: 2.0313vw;
  margin-top: 5.0313vw;
  display: flex;
  flex-direction: column;
  background-color: rgb(22, 22, 26);
  
}
.main-content .content-list:not(:last-child) {
  margin-bottom: 2.2396vw;
}
