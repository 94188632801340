.container_box {
  flex: 1;
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 8px;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #99ceff97;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #1890ff;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
.item_box {
  float: left;
  width: 88px;
  height: 88px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  .item_box_icon {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 14px;
    color: #1890ff;
  }
  &:hover {
    background-color: #daf5ff;
  }
}
.check_icon {
  position: absolute;
  width: 24px;
  height: 24px;
  background: #fff;
  top: 4px;
  left: 4px;
  border-radius: 3px;
  padding: 4px;
  box-sizing: border-box;
  z-index: 3;
  display: none;
}
.item_box:hover .check_icon {
  display: block;
}
.item_cover {
  width: 48px;
  height: 48px;
  object-fit: contain;
}
.item_name {
  width: 100%;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.selected_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0;
  top: 0;
}
.checked_icon {
  top: 8px;
  left: 8px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.create_new_folder {
  width: 94px;
  height: 94px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #000;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-bottom: 4px;
  box-sizing: border-box;
  cursor: pointer;
  padding-top: 15px;
}
.create_new_folder_img {
  margin-top: 15px;
  width: 49px;
}
.right_button_modal {
  position: absolute;
  width: 160px;
  border-radius: 6px;
  padding: 4px 0;
  box-shadow: 2px 3px 12px 3px rgba(28, 28, 32, 0.1);
  background: #fff;
  z-index: 999999;
  overflow: hidden;
}
.right_button_modal_item {
  height: 34px;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #5c5c5c;
  box-sizing: border-box;
  margin: 4px 0;
  cursor: pointer;
  &:hover {
    background: #f6f6f6;
    color: #121212;
  }
  .item_icon {
    font-size: 13px;
    margin-right: 4px;
  }
}
.item_lists {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  border-radius: 14px;
  cursor: pointer;
  user-select: none;
  margin: 3px 0;
  &:first-of-type {
    margin-top: 8px;
  }
  &:last-of-type {
    margin-bottom: 12px;
  }
  &:hover {
    background-color: #daf5ff;
  }
  & > img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin: 0 4px 0 8px;
  }
  & > div:first-of-type {
    flex: 1;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
