.one {
  .content {
    display: flex;
    min-height: 145px;
  }

  .content.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.title-text {
  font-size: 26px;
  color: #ffffff;
}

.title {
  margin-bottom: 1.0417vw;
  display: flex;
  justify-content: space-between;
}

.list {
  display: flex;
  flex-wrap: nowrap;
}

.list.one_fds232 li {
  margin-bottom: 25px;
}

.list.one_fds232 li:not(:last-child) {
  margin-right: 0.525vw;
}

.list li:not(:last-child) {
  margin-right: 0.625vw;
}

@media screen and (min-width: 1920px) {
  .one .list li:not(:last-child) {
    margin-right: 10px;
  }
}
