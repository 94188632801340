.footer_fdfsd {
  padding-top: 3.3854vw;
  background-color: rgb(22, 22, 26);

  .content_fdf {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100%;
    border-top: 1px solid #242529;

    p {
      text-align: center;
    }

    .a {
      align-self: center;
      margin-top: 1vw;
      margin-bottom: 0.5729vw;
      font-size: 12px;
      color: #ebebeb;
      display: flex;
      justify-content: flex-start;

      img {
        cursor: pointer;
      }

      ul li:not(:last-child) {
        margin-right: 40px;
      }

      ul {
        display: flex;

        .an {
          img {
            width: 109px;
            height: 24px;
          }
        }

        .lian_xi {
          display: flex;

          .imgs {
            display: flex;

            justify-content: flex-start;
          }
        }

        li {
          span,
          img {
            vertical-align: middle;
          }
        }
      }
    }

    .b {
      margin-bottom: 3.5417vw;
      font-size: 12px;
      color: #999999;
    }
  }

  .fsd2ik {
    color: rgb(151, 151, 151);
    font-size: 14px;
  }

  .fdsjifo23 {
    color: #fff;
    margin-top: 19px;
    font-size: 14px;
    opacity: 0.8;

    img {
      width: 22px;
      height: 22px;
      margin-right: 22px;
    }
  }

  .msg_text_gf {
    margin-right: 10px;
  }

  .msg_text_box {
    width: 290px;
    display: flex;
    align-items: center;
  }

  .msg_text_box_s {
    width: 476px;
    display: flex;
    align-items: center;
  }

  .msg_text {
    width: 100px;
    margin-right: 25px;
    font-size: 14px;
    color: #ffffff;
    text-align: right;
    opacity: 0.8;
  }

  .msg_text_s {
    width: 350px;
    font-size: 14px;
    color: #ffffff;
    opacity: 0.8;
  }
}

.two_func {
  display: flex;
  width: 100%;
  background-color: rgb(53, 53, 53);
  justify-content: center;
  padding: 25px 0;

  span {
    color: #fff;
    font-size: 14px;
    margin-right: 8px;
  }
}

@media screen and (min-width: 1920px) {
  .footer_fdfsd {
    width: 1920px;
    margin: 0 auto;
  }
}
