.top {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  background: #2a2a32;
  img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    vertical-align: middle;
    border-radius: 50%;
  }
  span {
    font-size: 16px;
    color: #ffffff;
    margin-right: 7px;
    vertical-align: middle;
  }
  .icon {
    width: 20px;
    height: 16px;
    vertical-align: middle;
  }
  .right {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
