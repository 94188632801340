.item {
  width: 10.9375vw;
  cursor: pointer;
  .wrapper {
    width: 100%;
    height: 6.1198vw;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
  .name {
    font-size: 16px;
    color: #ffffff;
    margin: 12px 0 10px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .msg {
    font-size: 13px;
    color: #999999;
    line-height: 1;
  }
}
