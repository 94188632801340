.pan_header {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 4px solid #f5f5f5;
}
.pan_header_info {
  display: flex;
  align-items: center;
}
.pan_header_info_logo {
  width: 26px;
  height: auto;
  cursor: pointer;
}
.pan_header_info_name {
  display: flex;
  align-items: center;
  margin-left: 6px;
  font-size: 20px;
  color: #000000;
}
.pan_header_info_name span:first-child {
  font-size: 20px;
  color: #000000;
  margin-right: 7px;
  line-height: 1;
}
.pan_header_info_name span:last-child {
  font-size: 14px;
  color: #000000;
  margin-left: 7px;
}
.pan_header_rightBar {
  display: flex;
  align-items: center;
}
.pan_header_userInfo {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.userInfo_avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
}
.userInfo_name {
  margin-left: 8px;
  font-size: 10px;
  color: #000000;
  margin-right: 6px;
}
.userInfo_vip {
  width: 11px;
  height: 10px;
}
.become_vip {
  width: 80px;
  height: 24px;
  font-size: 12px;
  background-image: url('../../assets/btn_bg.png');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: #333;
  cursor: pointer;
}
.vertical_line {
  width: 2px;
  background: #e9e9e9;
  height: 16px;
}
