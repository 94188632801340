.loading {
  display: flex;
}

.loading li {
  width: 0.4rem;
  height: 3rem;
  margin: 0 0.4rem;
  border-radius: 0.5rem;
  transform: scaleY(1);
  animation: a 0.7s linear infinite;
}

.loading li:nth-of-type(2) {
  animation-delay: 60ms;
}

.loading li:nth-of-type(3) {
  animation-delay: 120ms;
}

.loading li:nth-of-type(4) {
  animation-delay: 180ms;
}

.loading li:nth-of-type(5) {
  animation-delay: 240ms;
}

.loading li:nth-of-type(6) {
  animation-delay: 300ms;
}

@keyframes a {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.6);
  }

  100% {
    transform: scaleY(1);
  }
}