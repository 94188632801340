.modal_content {
  background: #fff;
  border-radius: 10px;
  width: 440px;
  box-sizing: border-box;
}
.modal_header {
  border-radius: 10px 10px 0 0;
  padding: 0 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
}
.modal_title {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.modal_close {
  color: #999;
  font-size: 16px;
  cursor: pointer;
}
.modal_container {
  display: flex;
  flex-direction: column;
}
.modal_container_img {
  width: 92px;
  padding: 30px 0;
}
.create_folder_name_input {
  width: 100%;
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  height: 38px;
  box-sizing: border-box;
  background: rgba(132, 131, 141, 0.16);
  color: #000;
  font-size: 14px;
}
.modal_footer {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  height: 68px;
  background: rgba(245, 245, 246, 1);
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
}
.modal_confirm_btn {
  background: #3699ff;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 52px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}
.modal_confirm_btn:hover {
  background: #65b1fd;
}
.new_folder {
  color: #3699ff;
  cursor: pointer;
}
.new_folder:hover {
  color: #65b1fd;
}
.footer_btn {
  display: flex;
  align-items: center;
}
.modal_footer_btn {
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  box-sizing: border-box;
  height: 32px;
  border-radius: 5px;
}
.cancel_btn {
  color: rgba(37, 38, 43, 0.72);
  box-shadow: inset 0 0 0 1px rgba(132, 131, 141, 0.16);
  transition: all linear 0.2s;
}
.cancel_btn:hover {
  background: #bcddfd;
  color: #65b1fd;
  box-shadow: none;
}
.move_btn {
  color: #fff;
  background: #3699ff;
  margin-left: 16px;
}
.move_btn:hover {
  background: #65b1fd;
}
.bread_content {
  padding: 0 20px 12px 20px;
  display: flex;
  width: 100%;
  font-size: 12px;
  box-sizing: border-box;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.breadcrumb_item {
  display: flex;
  align-items: center;
}
.breadcrumb_item_spearator {
  font-size: 13px;
}
.breadcrumb_item:last-child .breadcrumb_item_spearator {
  display: none;
}
.breadcrumb_item_link {
  display: flex;
  align-items: center;
  color: rgba(37, 38, 43, 0.72);
  cursor: pointer;
  transition: all linear 0.3s;
}
.breadcrumb_item_link:hover {
  color: #3699ff;
}
.breadcrumb_item:last-child .breadcrumb_item_link {
  color: rgba(37, 38, 43, 1);
  cursor: auto;
}
.breadcrumb_item:last-child .breadcrumb_item_link:hover {
  color: rgba(37, 38, 43, 1);
}
.page_folders {
  min-height: 320px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.page_folder_item {
  display: flex;
  height: 44px;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all linear 0.3s;
  font-size: 14px;
}
.page_folder_item:hover {
  background: #f5f5f6;
}
.folder_img {
  width: 23px;
  height: 23px;
  margin-right: 12px;
  object-fit: contain;
}
.create_folder_btn {
  display: flex;
  justify-content: space-between;
  width: 48px;
}
.create_confirm {
  color: #1890ff;
  font-size: 18px;
  cursor: pointer;
}
.create_cancel {
  color: #999;
  font-size: 18px;
  cursor: pointer;
}
.create_folder_input {
  width: 292px;
  height: 36px;
  box-sizing: border-box;
  padding: 8px 12px;
  outline: none;
  border: none;
  border-radius: 5px;
  background: #ebebed;
  margin-right: 12px;
}
.page_create_folder_item {
  display: flex;
  height: 44px;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 14px;
}
