.comment_setting {
  width: 280px;
  height: 340px;
  background: #000000;
  opacity: 0.9;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: -350px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, 0);
  .vip_icon {
    position: absolute;
    right: -11px;
    top: -7px;
  }
  .header {
    width: 100%;
    font-size: 14px;
    color: #848487;
    padding: 10px 0;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    div {
      width: 100%;
      text-align: center;
    }
    div.active {
      color: #ffffff;
    }
  }
  .btns {
    position: relative;
  }
  .btns.active_view {
    color: #ffffff;
  }
  .btns.active_view::after {
    content: '';

    width: 24px;
    height: 2px;
    background: #e95555;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
  .sdfasfsa {
    padding: 21px 0px 0px 31px;
    flex: 1 1;
    height: 200px;
  }
  .msg {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 18px;
  }
  .btns_row {
    margin-bottom: 19px;
    display: flex;
    flex-wrap: wrap;
  }
  .current_style {
    position: absolute;
    bottom: -2px;
    right: 0;
    width: 16px;
    height: 10px;
    border-radius: 8px 0px 8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 8px;
      height: 6px;
    }
  }
  .color_botton {
    position: relative;
    width: 50px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    border-radius: 12px;
    margin-right: 18px;
    margin-bottom: 20px;
  }
  .golden {
    border: 1px solid #fed083;
    color: #fed083;
  }
}
