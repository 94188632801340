.tv {
  width: 100%;
  box-sizing: border-box;
  padding: 0 78px;
  display: flex;
  flex: 1 1 auto;
}
.left {
  flex: 1 1;
  height: 41.8229vw;
  position: relative;
}

.shouqi {
  position: absolute;
  top: 50%;
  cursor: pointer;
  left: 0px;
  color: white;
  padding: 8px 5px;
  border-radius: 0px 8px 8px 0px;
  background: #323335;
  transform: translateY(-50%);
}
.vip_icon_fds23 {
  width: 22px;
  height: 14px;
  margin-left: 8px;
  right: -11px;
  top: -7px;
}
.hint {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 22px;
    color: #ffffff;
    margin-bottom: 43px;
  }
  .btn {
    width: 290px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    background: linear-gradient(90deg, #fee2ad, #fecf80);
    border-radius: 4px;
  }
}
.right {
  width: 336px;
  position: relative;
  box-sizing: border-box;
  padding-left: 30px;
  padding-top: 19px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 41.8229vw;
  overflow: hidden;
  li {
    cursor: pointer;
    position: relative;
  }
  li.active {
    color: #f85959;
  }
  li:hover {
    color: #f85959;
  }
}
.right {
  font-size: 18px;
  font-weight: 500;
  color: #eaeaea;
  background: #1e1e24;
}
.right li:not(:last-child) {
  margin-bottom: 1.6146vw;
}
