.modal_content {
  background: #fff;
  border-radius: 10px;
  width: 340px;
  box-sizing: border-box;
}
.modal_header {
  border-radius: 10px 10px 0 0;
  padding: 0 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
}
.modal_title {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.modal_close {
  color: #999;
  font-size: 16px;
  cursor: pointer;
}
.modal_container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal_container_img {
  width: 92px;
  padding: 30px 0;
}
.create_folder_name_input {
  width: 100%;
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  height: 38px;
  box-sizing: border-box;
  background: rgba(132, 131, 141, 0.16);
  color: #000;
  font-size: 14px;
}
.modal_bottom {
  border-radius: 0 0 10px 10px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}
.modal_confirm_btn {
  background: #3699ff;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 52px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.modal_confirm_btn:hover {
  background: #65b1fd;
}
