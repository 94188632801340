.modalBody {
  height: 100vh;
  top: 0;

  :global(.ant-modal-content) {
    background-color: transparent;
    box-shadow: none;
  }

  :global(.ant-modal-body) {
    padding: 0;
    height: 100vh;
  }

  img {
    max-width: 100%;
    max-height: 100vh;
    object-fit: contain;
  }
}

.pre_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
