.pan_LeftSide {
  width: 128px;
  font-size: 12px;
  color: #535353;
  background: #fff;
  border-right: 4px solid #f5f5f5;
  box-sizing: border-box;
}
.pan_leftSide_main {
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  padding-right: 4px;
  box-sizing: border-box;
  & > img {
    width: 28px;
    height: auto;
    margin-right: 4px;
  }
}
.pan_leftSide_main_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0;
}
.item_img {
  width: 32px;
  text-align: center;
}
.sub_item {
  position: relative;
  font-size: 12px;
}
.sub_item_content {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 32px;
  cursor: pointer;
}
.sub_item_content.active {
  background: #e0f2fe;
  color: #3699ff;
}
.sub_item_content.active::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 3px;
  background: #3699ff;
  top: 0;
  left: 0;
}
.sub_item_content_panel {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 12px;
  height: 28px;
  cursor: pointer;
}
.sub_item_icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.sub_item_icon.sub_item_iconSamll {
  width: 6px;
  left: 14px;
}
.sub_item_icon.sub_item_iconSamll.dropdown {
  transform: rotate(90deg);
}
.sub_item_icon.sub_item_iconBig {
  width: 19px;
  left: 12px;
}
.sub_item:last-child .sub_item_icon.sub_item_iconBig {
  width: 6px;
  left: 14px;
}
.sub_sub_item {
  height: 28px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  cursor: pointer;
  position: relative;
}
.sub_sub_item.active {
  color: #3699ff;
  background: #e0f2fe;
}
.sub_sub_item.active::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 3px;
  background: #3699ff;
  top: 0;
  left: 0;
}
.sub_sub_icon {
  width: 10px;
  margin-right: 14px;
}
