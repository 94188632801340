.input_fdsfds {
  width: 100%;
  color: white;
  font-size: 14px;
  border: none;
  outline: none;
  position: relative;
  background-color: rgba(42, 42, 50, 1);
  padding: 10px 0;

  &:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-text-fill-color: #ededed !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
  }
}

.input {
  position: relative;

  .ant-form-item-explain {
    div {
      color: red;
    }
  }
}

.line {
  width: 100%;
  background: #393940;
  height: 1px;
}

.right {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #666666;
}