.charge {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 901;
  background-color: rgba($color: #000000, $alpha: 0.2);
}
.content {
  width: 738px;
  height: 556px;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: column;
  left: 50%;
  transform: translate(-50%, -50%);
}
.enter_btn {
  background-color: rgb(18, 18, 21);
  border-radius: 8px;
  padding: 8px 15px;
  color: white;
  cursor: pointer;
}
.main_content {
  width: 100%;
  flex: 1 1;
  box-sizing: border-box;
  padding: 0 30px;
  padding-top: 27px;
}
.title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #242529;
  margin-bottom: 21px;
}
.options {
  margin-bottom: 32px;
}
.left {
  width: 164px;
}
.sdij2d {
  width: 100%;
  height: 210px;
  display: flex;
  border: 1px solid #eaeaea;
}
.right {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .asfeas {
    padding: 6px 20px;
    font-size: 14px;
    color: #fff;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    background: #1e80ff;
    margin: 20px;
  }
  .dji92 {
    width: 135px;
    height: 135px;
    background: url('./images/bg_code.png');
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
    img {
      width: 115px;
      height: 115px;
      font-weight: Regular;
    }

  }
  .text {
    position: absolute;
    bottom: 10px;
    left: 21%;
    font-size: 12px;
    color: #999999;
    img {
      margin-right: 4px;
      vertical-align: middle;
    }
  }

  .money {
    font-size: 30px;
    color: rgba(248, 89, 89, 1);
  }
  .stylefds {
    font-size: 14px;
    color: #666666;
  }
}
.play.active {
  background-color: white;
}
.play {
  padding: 15px 0;
  cursor: pointer;
  text-align: left;
  box-sizing: border-box;
  padding-left: 20px;

  img {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-right: 8px;
  }
  span {
    font-size: 12px;
    color: #666666;
  }
}
.left {
  background: #eeeff3;
  height: 100%;
}
