.wrapper_fjds234 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: rgba($color: #000000, $alpha: 0.2);
  min-height: 650px;
}
.user-login-model {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 424px;
  box-sizing: border-box;
  padding: 16px 19px 0 20px;
  border-radius: 8px;
  background: #2a2a32;
  .top_fdsf23 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 46px;
    .right {
      cursor: pointer;
    }
    img {
      width: 100px;
      vertical-align: middle;
      height: 24px;
      margin-right: 8px;
    }
    span {
      font-size: 16px;
      color: #fefefe;
      vertical-align: middle;
      line-height: 24px;
      opacity: 0.6;
    }
  }
  .bottom_fio23j {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 54px;
    background: rgba(37, 37, 43, 1);
    display: flex;
    justify-content: space-between;
    border-radius: 0px 0px 8px 8px;
    box-sizing: border-box;
    padding: 0 20px;
    line-height: 54px;
    font-size: 14px;
    color: #666666;
    span {
      cursor: pointer;
    }
  }
}
.btn_login {
  color: white;
}
.user-login-model {
  height: auto;
  padding-bottom: 54px;
}

.btn_login {
  color: white;
  height: 48px;
  width: 100%;
  cursor: pointer;
  border-radius: 24px;
  text-align: center;
  margin-bottom: 0.7813vw;
  line-height: 48px;
  background: linear-gradient(-90deg, #f85959, #ff546b);
}
