.annal {
  .text {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 32px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      margin-bottom: 19px;
    }
  }
}
