.clause {
  overflow-y: auto;
  height: 100%;
}
.title {
  font-size: 20.5pt;
  color: black;
  text-align: center;
  margin: 20px 0;
  font-weight: 700;
}
.one {
  font-size: 15.5pt;
  color: black;
  font-weight: bold;
}
.msg {
  margin-bottom: 15px;
  font-size: 10.5pt;
  color: black;
}
.two {
  margin: 10px 0;
  font-size: 10.5pt;
  color: black;
}
.msg {
  font-size: 10.5pt;
  color: black;
}
.a {
  color: #f15b3a;
}
