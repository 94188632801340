#home {
  width: 100vw;
  background-color: rgb(22, 22, 26);

  ._w {
    width: inherit;
    //height: 30.8333vw;
    height: 24.7333vw;
  }

  .primary {
    width: inherit;
    height: 30.83vw;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
    left: 50%;
    transform: translate(-50%, 0);
    .left-mask {
      width: 8.33vw;
      position: absolute;
      left: 0;
      top: 0;
      height: inherit;
      background-image: linear-gradient(270deg, rgba(22, 22, 26, 0), #16161a);
    }
    .top-mask {
      width: inherit;
      height: 4.69vw;
      position: absolute;
      left: 0;
      top: 0;
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(22, 22, 26, 0.8)),
        to(rgba(22, 22, 26, 0))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(22, 22, 26, 0.8),
        rgba(22, 22, 26, 0) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(22, 22, 26, 0.8),
        rgba(22, 22, 26, 0) 100%
      );
    }
    .right-mask {
      position: absolute;
      top: 0;
      width: 8.33vw;
      height: inherit;
      background-image: linear-gradient(90deg, rgba(22, 22, 26, 0), #16161a);
      right: 0;
    }
    .bottom-mask {
      position: absolute;
      bottom: 0;
      right: 0;
      width: inherit;
      height: 11.46vw;
      background: linear-gradient(180deg, rgba(22, 22, 26, 0), #16161a 100%);
    }
  }
}
.huan_fds2s:hover {
  .huan_img_fdji2 {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background-image: url('../../assets/images/h_huan.png');
  }
  span {
    color: #f85959;
  }
}
.huan_fds2s {
  display: flex;
  cursor: pointer;
  align-items: center;
  .huan_img_fdji2 {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background-image: url('../../assets/images/huan.png');
  }
  span {
    font-size: 16px;
    color: #999999;
  }
}
@media screen and (min-width: 1920px) {
  #home {
    width: 1920px;
    margin: 0 auto;
  }
}
