.nav_info_line {
  height: 32px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.nav_info_block {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.nav_icon {
  display: flex;
  align-items: center;
}
.nav_divider {
  width: 1px;
  height: 14px;
  background: #000000;
  margin: 0 8px;
}
.nav_info {
  display: flex;
}
.nav_info_text {
  display: flex;
  align-items: center;
}
