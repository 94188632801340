.content-item-two {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  height: 100%;
}
.one {
  .content {
    display: flex;
  }
}
.title-text {
  font-size: 26px;
  color: #ffffff;
}
.title {
  margin-bottom: 1.0417vw;
}
.list {
  display: flex;
  flex-wrap: nowrap;
}
.list li:not(:last-child) {
  margin-right: 0.625vw;
}
@media screen and (min-width: 1920px) {
  .list li:not(:last-child) {
    margin-right: 12px;
  }
}

@media screen and (min-width: 1920px) {
  #dsjif232od {
    .img, .image-placeholder {
      width: 432px;
      height: 241px;
      flex: none;
    }
    .text {
      width: 432px;
    }
  }
}
