.register {
  position: relative;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
  div {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #999999;
    position: relative;
  }
  div.active {
    color: #ffffff;
  }
  div.active::after {
    content: '';
    width: 1.6667vw;
    height: 3px;
    background: white;
    position: absolute;
    bottom: -6px;
    left: 50%;
    border-radius: 1px;
    transform: translate(-50%, 0);
  }
}
.btns > div:first-child {
  margin-right: 2.1354vw;
}
.content {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.3542vw;
  position: relative;
}
.get_code_check {
  font-size: 14px;
  color: #f85959;
}
.sdfsd {
  margin-bottom: 1.7708vw;
}
.btn_login {
  height: 48px;
  color: white;
  width: 100%;
  cursor: pointer;
  border-radius: 24px;
  text-align: center;
  margin-bottom: 0.7813vw;
  margin-top: 47px;
  line-height: 48px;
  background: linear-gradient(-90deg, #f85959, #ff546b);
}
.msg {
  color: #666666;
  p {
    margin-bottom: 3px;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
  }
}
