.history {
  width: 100%;
  min-height: 600px;
  padding: 0 78px;
  padding-top: 52px;
  background-color: rgba(18, 18, 18, 1);
  box-sizing: border-box;
}
.content {
  width: 100%;
  display: flex;
}
.left {
  width: 210px;
  display: flex;
  flex-direction: column;
  background-color: rgb(36, 36, 43);
  margin-right: 12px;
  height: 363px;
}
.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  background: url('./images/bg.png');
  background-size: 200% 100%;
  margin-bottom: 20px;
  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 12px;
  }
}
.right {
  flex: 1 1;
  .p {
    font-size: 20px;
    color: #ffffff;
  }
}
.xu_fei {
  width: 84px;
  height: 30px;
  background: linear-gradient(90deg, #fee2ad, #fecf80);
  border-radius: 15px;
  font-size: 13px;
  color: #5b3301;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 29px;
  cursor: pointer;
}
.user {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 11px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding: 0 6px;
  box-sizing: border-box;
}
.timer {
  font-size: 13px;
  color: #666666;
  margin-bottom: 21px;
}
.bottom {
  background: #0d0f12;
  border-radius: 8px;
  a {
    display: block;
    height: 57px;
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    text-align: center;
    line-height: 57px;
  }
  .active {
    color: #e45253;
  }
}
