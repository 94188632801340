.slider {
  border-radius: 26px;
  box-shadow: 0 0 3px #999;
  background-color: #ddd;
  overflow: hidden;
}
.fdsafijo {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bg-green {
  background-color: #78c430;
}

.bg-red {
  background-color: #ff5500;
}

.button {
  border-radius: 26px;
}

.button div {
  top: 10px;
}
