.phone_input {
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  align-items: center;
  transition: 0.3s;
  position: relative;
  input {
    width: 100%;
  }
  p {
    margin-right: 12px;
    padding-right: 12px;
    margin-bottom: 0;
    white-space: nowrap;
    border-right: 1px solid #393940;
  }
  .phone_input_wrapper {
    width: 228px;
    height: auto;
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 999999;
    box-sizing: border-box;
    height: 300px;
    overflow: auto;
    background-color: rgb(22, 22, 26);
    div {
      text-align: center;
      margin: 10px 0;
      cursor: pointer;
      padding: 8px 0;

      transition: margin-left 0.3s;
    }
    div:hover {
      background: linear-gradient(to right, #fee2ad, #fecf80) !important;
      color: rgb(22, 22, 26);
      margin-left: 25px;
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #eeb0a662;
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #fee2ad;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  .phone_input_wrapper .Input_line__2MLPA {
    margin-top: 8px;
  }
  .item {
    display: flex;
    span {
      margin: 0 15px;
    }
  }
  .item.global_active_bg_fdji23 {
    color: rgb(22, 22, 26);
  }
  .Input_input__3nGqZ {
    width: 100%;
  }
}
