.prism-setting-btn,
.prism-cc-btn {
  display: none !important;
}
.prism-big-play-btn {
  position: absolute;
  left: 50% !important;
  bottom: 50% !important;
  transform: translate(-50%, 50%) !important;
}
