.title-text {
  font-size: 26px;
  color: #ffffff;
}

.title {
  margin-bottom: 1.0417vw;
}

.two {
  .content.loading {
    justify-content: center;
    align-items: center;
  }

  .content {
    display: flex;
    min-height: 280px;

    .right li:not(:last-child) {
      margin-right: 0.6vw;
    }

    .right div:first-child {
      margin-bottom: 0.625vw;
    }
  }

  .left {
    height: 18.2292vw;
  }

  .right {
    height: 18.2292vw;
    width: 100%;
  }
}

.three {
  .content {
    display: flex;
  }
}

.list {
  flex-wrap: nowrap;
  display: flex;
}

.list li:not(:last-child) {
  margin-right: 0.625vw;
}
