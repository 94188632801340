.content-item-one {
  display: flex;
  flex-direction: column;
  display: inline-block;
  cursor: pointer;
  .image-placeholder {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  img, .image-placeholder {
    width: 10.9375vw;
    height: 6.1458vw;
    border-radius: 0.4167vw;
    margin-bottom: 0.3125vw;
  }
  .title_ {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 0.16771vw;
    width: 10.9vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .msg {
    font-size: 14px;
    color: #999999;
    width: 10.9vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media screen and (min-width: 1920px) {
  .content-item-one {
    img {
      width: 210px;
      height: 117px;
    }
    .title_ {
      width: 210px;
    }
    .msg {
      width: 210px;
    }
  }
}
