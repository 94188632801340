.video_box {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  background: #000;

  .style {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .control_left.full_screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .control_left,
  .control_right {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .control {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    background: #68666688;
    z-index: 20;
  }

  .img {
    width: 20px;
    cursor: pointer;
    vertical-align: middle;
    position: relative;
  }

  .video_progress {
    margin-right: 19px;
  }

  .next {
    margin-right: 13px;
  }

  .time {
    font-size: 12px;
    color: #ffffff;
    vertical-align: middle;
  }

  .time_bar {
    color: #fff;
    position: absolute;
    border-radius: 5px;
    padding: 6px 10px 4px;
    line-height: 1;
    top: -35px;
    left: 0;
    background: #68666688;
  }

  .sound,
  .qing_xi,
  .bei_su {
    display: flex;
    align-items: center;
    margin-right: 18px;
    font-size: 14px;
    cursor: pointer;
    color: #ffffff;
    position: relative;
  }

  .line_bar:hover {
    transform-origin: center;
    transform: scaleY(2.5);
  }

  .line_bar {
    transition: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;

    .video_rate {
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background: #f85959;
    }
  }

  .fun_box {
    position: absolute;
    bottom: 35px;
  }

  .video_voice {
    width: 76px;
    height: 220px;
    background: #68666688;
    border-radius: 4px;
    position: absolute;
    right: -20px;
    bottom: 100%;
    box-sizing: border-box;
    padding: 22px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-bottom: 17px;
      color: #ffffff;
      font-size: 14px;
    }

    .bg_color {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: rgb(246, 86, 86);
    }

    .bar_line {
      height: 132px;
      position: relative;
      border-radius: 3px;
      width: 5px;
      padding-bottom: 7px;
      background: #393a3d;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .bar_mine {
        width: 15px;
        height: 15px;
        background: #ffffff;
        border-radius: 50%;
        position: absolute;
        top: -14px;
        left: 50%;
        transform: translate(-50%, 0);
      }

      .line {
        position: relative;
        width: 100%;
        height: 0%;
        background-color: rgb(246, 86, 86);
      }
    }
  }

  .video_speed,
  .qingxidu_list {
    width: 80px;
    height: auto;
    background: #68666688;
    border-radius: 4px;
    right: -20px;
    padding: 24px 26px 0 26px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    bottom: 100%;

    span {
      margin-bottom: 24px;
      text-align: center;
    }

    span.active {
      color: #f85959;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .hide {
    display: none;
  }

  .video_loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .user_mutual {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .video_cache {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
    z-index: 0;
  }

  .video_pause {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    & > img {
      width: 36px;
      height: auto;
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.pause_image {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 400px;
    height: 225px;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    background: rgba(1, 1, 1, 0.1);

    & > div {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.66);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 16px;
        opacity: 0.66;
      }
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.advert_box {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > video {
    width: 100%;
    height: 100%;
    background: #000;
  }

  & > div:first-of-type {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 101;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 1px;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.2);
    padding: 8px 14px 8px 16px;
    box-sizing: border-box;
    border-radius: 20px;
    opacity: 0.6;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.9;
    }

    & > span:first-of-type {
      margin-right: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      border-right: 1px solid #fff;
    }
  }
}

.video_advert {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
//全屏按钮
.video_advert::-webkit-media-controls-fullscreen-button {
  display: none;
}
// 播放按钮
.video_advert::-webkit-media-controls-play-button {
  display: none;
}
//进度条
.video_advert::-webkit-media-controls-timeline {
  display: none;
}
//观看的当前时间
.video_advert::-webkit-media-controls-current-time-display {
  display: none;
}
//剩余时间
.video_advert::-webkit-media-controls-time-remaining-display {
  display: none;
}
