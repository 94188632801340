.annal {
  .text {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 32px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      margin-bottom: 19px;
    }
    ul li:not(:last-child) {
      margin-right: 12px;
    }
  }
}
.fjdsioj2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 69.6354vw;
  padding: 103px 0px 0px 000px;
  margin: 0 auto;
}
