.top_bar {
  height: 34px;
  display: flex;
  padding: 0 12px;
  align-items: center;
  background: #f9fafc;
  font-size: 12px;
  & > span {
    color: rgb(236, 114, 114);
    margin-left: 12px;
  }
}
.top_bar_files {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  background: #e5f0fd;
  border-radius: 12px;
  color: #3699ff;
  padding: 0 12px;
  box-sizing: border-box;
}
.right_block_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  .item_icon {
    font-size: 13px;
    margin-right: 4px;
  }
}
.right_block_divider {
  width: 1px;
  height: 12px;
  background: #3699ff;
  margin: 0 8px;
}
