.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 10px 20px;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 18px;
  max-width: 300px;
  z-index: 1001;
  color: white;
  background-color: rgba($color: #000000, $alpha: 0.8);
}
