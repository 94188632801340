.upload_block {
  width: 94px;
  height: 94px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
  box-sizing: border-box;
}
.upload_square {
  width: 49px;
  height: 49px;
  border: 2px dashed #e8e9ee;
  position: relative;
  border-radius: 6px;
  margin-top: 12px;
  box-sizing: border-box;
  cursor: pointer;
}
.upload_square::after {
  position: absolute;
  content: '';
  width: 2px;
  height: 20px;
  background: #e8e9ee;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.upload_square::before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background: #e8e9ee;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.text {
  margin-top: 0px;
  text-align: center;
}
.upload_btn {
  display: flex;
  width: 70px;
  height: 25px;
  background: #3699ff;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
.upload_icon {
  width: 19px;
  height: 19px;
}
.uploadImgContainer {
  width: 49px;
  height: 49px;
  border: 2px dashed #e8e9ee;
  position: relative;
  border-radius: 6px;
  margin-top: 12px;
  box-sizing: border-box;
  cursor: pointer;
}
.notificationBox {
  width: 326px;
  padding: 12px;
  :global(.ant-notification-notice-message) {
    font-size: 14px;
    margin-bottom: 6px;
    margin-left: 28px;
  }
  :global(.ant-notification-notice-description) {
    font-size: 12px;
    margin-left: 28px;
    word-break: break-all;
  }
  :global(.ant-notification-notice-icon) {
    color: #1890ff;
    font-size: 18px;
    margin-left: 0;
  }
  :global(.ant-notification-notice-close) {
    top: 12px;
    right: 16px;
  }
}
