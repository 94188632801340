.barrage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.comment_item {
  white-space: nowrap;
}

.comment_item.is_vip {
  padding: 5px 8px;
  border-radius: 8px;
}

.comment_item.normal {
  transition: left 13s linear;
}

.comment_item.kuai {
  transition: left 10s linear;
}

.comment_item.man {
  transition: left 15s linear;
}