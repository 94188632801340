.mask-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding-top: 1.21rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  .jiantou {
    width: 0.96rem;
    height: 1.05rem;
    position: absolute;
    top: 0.23rem;
    right: 0.41rem;
  }
  p {
    font-weight: 500;
    color: #ffffff;
    font-size: 0.21rem;
    text-align: center;
  }
  .step {
    width: 0.22rem;
    height: 0.27rem;
  }
}
.mask-content p:nth-of-type(1) {
  margin-bottom: 0.16rem;
}
.mask-content p:nth-of-type(2) {
  margin-bottom: 0.09rem;
}
