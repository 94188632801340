.player {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 50%;
  height: 50%;
  background-color: white;
  position: relative;
}
.close {
  position: absolute;
  top: -30px;
  cursor: pointer;
  color: white;
  right: -30px;
}
