.tableCol {
  background-color: #fff !important;
  font-size: 12px;
  padding: 6px 12px !important;
}

.tableTitle {
  width: 100%;
  height: 32px;
  background: #f5f5f5;
}

.nameBox {
  display: flex;
  font-size: 12px;
  align-items: center;

  & > img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 6px;
  }

  & > div {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.btns {
  height: 24px;
  font-size: 12px;
  padding: 0 6px;
}
