.nav {
  width: inherit;
  display: flex;
  position: absolute;
  top: 29.5521vw;
  left: 50%;
  padding: 0 4.0567vw 0 4.0625vw;
  box-sizing: border-box;
  justify-content: flex-end;
  transform: translate(-50%, 0);
  .fdsdfsdf {
    display: flex;
    width: calc(100% - 400px);
    justify-content: space-between;
    align-items: center;
  }
  .fdsdfsdf a:not(:last-child) {
    margin-right: 2.2146vw;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    width: 144px;
  }
  .left {
    flex: 1 1;
    height: 41.8229vw;
    position: relative;
  }
  .right {
    width: 336px;
    position: relative;
    box-sizing: border-box;
    padding-left: 24px;
    padding-top: 19px;
    display: flex;
    flex-direction: column;
  }
  a:hover {
    .title,
    .msg {
      background: linear-gradient(to right, #fee2ad, #fecf80) !important;
      -webkit-background-clip: text !important;
      color: transparent !important;
    }
  }
  a.active {
    .title {
      font-weight: 700;
    }
    .bg {
      color: #999999;
    }
  }
  a {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
  }
  .bg {
    font-size: 26px;
    background-image: -webkit-linear-gradient(top, #626470, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .title {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 0.6771vw;
  }
  .msg {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }

  .nav_news::after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0)
    );
  }
  .dian_shi {
    margin-right: 1.5625vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100px;
  }
  .dian_shi::after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    right: -0.3vw;
    top: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0)
    );
  }
  .nav_news {
    display: flex;
    position: relative;
    width: 246px;
    color: white;
    flex-wrap: wrap;
    font-size: 16px;
    margin-left: 24px;
    padding-left: 31px;
    box-sizing: border-box;
    flex-direction: column;
    span {
      min-width: 33%;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
    }
    span:hover {
      color: #fecf80;
    }
    div.nav_news_item {
      display: flex;
      align-items: center;
    }
  }
}
