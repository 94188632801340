.explain {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba($color: #000000, $alpha: 0.8);
}
.content {
  width: 960px;
  height: 580px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 0 8px;
}
.close {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -15px;
  color: white;
  right: -15px;
  cursor: pointer;
}
