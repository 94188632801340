.notificationBox {
  width: 326px;
  padding: 12px;
  :global(.ant-notification-notice-message) {
    font-size: 14px;
    margin-bottom: 6px;
    margin-left: 28px;
  }
  :global(.ant-notification-notice-description) {
    font-size: 12px;
    margin-left: 28px;
    word-break: break-all;
  }
  :global(.ant-notification-notice-icon) {
    color: #1890ff;
    font-size: 18px;
    margin-left: 0;
  }
  :global(.ant-notification-notice-close) {
    top: 12px;
    right: 16px;
  }
}
