.item {
  width: 207px;
  height: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #eaeaea;
  cursor: pointer;
}
.item.active {
  background: #fff9f9;
  border: 1px solid #ffd6d6;
}
.fjsidoq2 {
  font-size: 20px;
  color: #6f1818;
  margin-bottom: 0;
}
.fjio2cc {
  font-size: 14px;
  color: #f85959;
  margin-bottom: 0;
}
.jdsi23 {
  font-weight: Bold;
  font-size: 29.96px;
}
.dsjio2oic {
  font-size: 14px;
  text-decoration: line-through;
  color: #999999;
  margin-bottom: 4px;
}
