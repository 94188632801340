.pan_page {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}
.pan_wrapper {
  display: flex;
  flex: 1;
  height: calc(100vh - 48px);
}
.pan_content {
  flex: 1;
  width: calc(100% - 128px);
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #99ceff97;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #1890ff;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.content_route {
  width: 100%;
  height: calc(100% - 42px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #99ceff97;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #1890ff;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.footInfo {
  width: 100%;
  height: 42px;
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
