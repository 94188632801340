.policy {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.title {
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 12px;
}
.center {
  text-align: center;
}
.msg {
  font-size: 14px;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}
.keynote {
  font-weight: bold;
  font-size: 16px;
  color: black;
}
