:root,
body {
  background-color: white;
  .down-load {
    width: 100%;
    height: 100vh;
    a {
      text-decoration: none;
    }
    background-image: url('./image/bg.png');
    background-size: contain;
    box-sizing: border-box;
    padding: 1.41rem 0 0.6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      width: 1.44rem;
      height: auto;
      margin-bottom: 0.28rem;
    }
    .msg {
      width: 1.25rem;
      height: auto;
      margin-bottom: 0.44rem;
    }
    .down-botton {
      width: 2.91rem;
      height: 0.67rem;
      border-radius: 0.33rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 0.35rem;
        height: 0.35rem;
        margin-right: 0.07rem;
      }
      span {
        font-size: 0.19rem;
        color: #ffffff;
      }
    }
    .down-botton.android {
      margin-bottom: 0.21rem;
      background-color: #f85959;
    }
    .down-botton.iphone {
      background-color: #4b5058;
      margin-bottom: 1.32rem;
    }
    .code-wrap {
      display: flex;
    }
    .code-down.android {
      margin-right: 0.33rem;
    }
    .code-down {
      width: 1.01rem;
      height: 1.01rem;
      background: #f5f5f9;
      border-radius: 0.13rem;
      box-sizing: border-box;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      span {
        text-align: center;
        position: absolute;
        width: 100%;
        left: 51%;
        bottom: -0.24rem;
        font-size: 0.16rem;
        color: #4b5058;
        transform: translate(-50%, 0);
      }
    }
  }
}
