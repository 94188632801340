.protocol {
  width: 100%;

  .content {
    width: 816px;
    margin: 0 auto;
    span,
    img {
      cursor: pointer;
    }
  }
  p {
    margin-bottom: 27px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 47px;
    img {
      width: 7.2917vw;
      height: 1.7708vw;
    }
  }
  .jfsido {
    margin-bottom: 12px;
  }
  .right {
    text-align: right;
  }
  .sdjfiosd {
    margin-bottom: 27px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin-bottom: 24px;
  }
  .text {
    font-size: 14px;
    color: #000000;
  }
}
