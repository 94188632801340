.item {
  color: #666666;
  padding: 5px 8px;
  border-radius: 3px;
  margin: 0 5px;
  cursor: pointer;
  background: #25252b;
  color: #ffffff;
}
.item.not-click {
  color: #666666;
  cursor: not-allowed;
}
.item.active {
  border: none;
  background: #f85959;
}
i {
  font-style: normal;
  margin: 0 3px;
}
i.current-page {
  font-weight: 700;
}
.wei {
  color: white;
}
