.player {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 150px;
  max-width: 1720px;
  margin: 0 auto;
  background-color: rgb(30, 30, 36);
}

.not_vip {
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 15px;
  }

  .btns span:first-child {
    border: 1px solid #fff;
    margin-right: 18px;
  }

  .btns span:last-child {
    background-color: rgb(248, 89, 89);
  }

  .btns {
    font-size: 17px;
    font-weight: 400;
    display: flex;
    span {
      cursor: pointer;
      border-radius: 5px;
      display: block;
      width: 195px;
      height: 35px;
      text-align: center;
      line-height: 35px;
    }
  }
}

.fu_fei {
  width: 34px;
  height: 11px;
  background-color: red;
  position: absolute;
  top: -14px;
  right: -17px;
  font-size: 10px;
}

.func_Fd9023 {
  .btns {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    height: 56px;
    justify-content: space-between;
  }

  position: absolute;
  width: 100%;
  top: 100%;
  background: #16161a;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 12px;

  .fdjsio2d.hide {
    opacity: 0;
  }

  .synopsis {
    width: 100%;

    .one_F {
      margin-bottom: 19px;
      font-size: 24px;
      margin-bottom: 19px;
      color: #ffffff;
    }

    .fdjsio2d {
      position: absolute;
      bottom: -130px;
      left: 15px;
      z-index: -9999;

      li {
        color: #ffffff;
        font-size: 14px;
        margin-bottom: 19px;
      }

      .right_Fji2o {
        color: #959599;
        margin-right: 8px;
      }
    }

    .two_Fd {
      font-size: 14px;
      vertical-align: middle;

      span {
        vertical-align: inherit;
      }

      .img {
        width: 10px;
        height: 6px;
        margin-right: 20px;
      }

      .active {
        span {
          color: #f85959;
        }
      }

      .fdsfdsdjio {
        padding: 5px 15px 5px 18px;
        border-radius: 12px;
        background: #2a2a32;
        margin-right: 10px;
      }

      .fsdjuio {
        margin: 0 5px;
        vertical-align: top;

        color: #ffffff;
      }

      .fdjsoi {
        margin-right: 9px;
      }

      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }

      color: #959599;
    }
  }

  .img {
    width: 22px;
    height: 22px;
  }

  .zan {
    .img {
      background-image: url('./images/zan.png');
    }
  }

  .zan:hover {
    color: #f85959;

    .img {
      background-image: url('./images/h_zan.png');
    }
  }

  .shou:hover {
    color: #f85959;

    .img {
      background-image: url('./images/h_shou.png');
    }
  }

  .zan.active {
    color: #f85959;

    .img {
      background-image: url('./images/a_zan.png');
    }
  }

  .shou.active {
    color: #f85959;

    .img {
      background-image: url('./images/a_shou.png');
    }
  }

  .shou {
    .img {
      background-image: url('./images/shou.png');
    }
  }

  .zan,
  .shou {
    width: 74px;
    height: 32px;
    cursor: pointer;
    color: #959599;
    background: #2a2a32;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .func_left {
    display: flex;
    align-items: center;
  }

  .func_right {
    display: flex;
    align-items: center;

    .comment_btns {
      display: flex;

      .on:hover {
        color: red;
      }

      .comment_off_on,
      .comment_setting {
        width: 26px;
        height: 26px;
        cursor: pointer;
        position: relative;
      }

      .comment_setting {
        margin-left: 12px;
        background-image: url('./images/setting.png');
      }

      .comment_setting:hover {
        background-image: url('./images/h_setting.png');
      }
    }

    .comment_input_bar {
      width: 320px;
      height: 32px;
      background: #0c0c0e;
      border-radius: 16px;
      display: flex;
      align-items: center;
      margin-left: 12px;

      .head_portrait {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
        margin-left: 6px;
      }

      .comment_input {
        flex: 1 1 auto;
        outline: none;
        border: none;
        background: #0c0c0e;
        font-size: 14px;
        color: #959599;
      }

      .btn {
        width: 60px;
        cursor: pointer;
        font-size: 14px;
        color: #ffffff;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #292a3c;
        border-radius: 0px 16px 16px 0px;
      }
    }
  }
}

.left {
  flex: 1 1;
  height: 782px;
  position: relative;
}

.player .sull_screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.right {
  width: 330px;
  height: 782px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 30px;
  box-sizing: border-box;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6f7ff7d;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #e6f7ffd0;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  li {
    position: relative;
  }

  .one {
    .name {
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;
    }

    margin-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btns img:first-child {
    margin-right: 12px;
  }

  .btns {
    img {
      cursor: pointer;
      width: 16.3px;
      height: 18.2px;
    }
  }
}

.sjdfio {
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  margin-bottom: 36px;
}

.sjdfiod {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;
}

.episode {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  box-sizing: border-box;
}

.item {
  width: 50px;
  margin-bottom: 20px;
  height: 36px;
  font-size: 16px;
  position: relative;
  font-weight: 500;
  color: #ffffff;
  vertical-align: text-top;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}

.item.active {
  color: rgba(248, 89, 89, 1);
  //background: #16161a;
  border-radius: 18px;
}

.item:hover {
  color: rgba(248, 89, 89, 1);
}

.retract {
  width: 25px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  right: -25px;
  background: #323335;
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 888;
  border-radius: 0 20px 20px 0;
}

.vipIconUi {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 22px;

  height: 14px;
  background: linear-gradient(90deg, #fee2ad, #fecf80);
  border-radius: 2px;
  font-size: 10px;
  color: #663d00;

  vertical-align: text-top;
}

.gold_box {
  height: 14px;
  position: absolute;
  right: -11px;
  top: -7px;
}

.gold_font {
  font-size: 11px;
  background: #ffd38b;
  color: white;
}

.vip_icon {
  width: 22px;
  height: 14px;
  position: absolute;
  right: -11px;
  top: -7px;
}

.not_vip {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
}
