* {
  margin: 0;
  padding: 0;
  list-style: none;
}
#root,
body {
  height: auto;
  min-height: 100%;
  position: relative;
}
body {
  background-color: rgb(22, 22, 26);
  overflow-x: hidden;
}
ul {
  margin-bottom: 0 !important;
}
._g_home-page {
  width: 100%;
}
.auto_fjisid23423 {
  flex: 1 1;
  height: 1000px;
  background-color: rgb(22, 22, 26);
}
.root_wrap {
  height: auto;
  min-height: 100%;
  display: flex;
  background-color: rgb(22, 22, 26);
  flex-direction: column;
}
.global_active_text_fdji23 {
  background: linear-gradient(to right, #fee2ad, #fecf80) !important;
  -webkit-background-clip: text !important;
  color: transparent !important;
}
.global_active_bg_fdji23 {
  background: linear-gradient(to right, #fee2ad, #fecf80) !important;
}
@mixin mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.2);
}
@media screen and (min-width: 1930px) {
  .root_wrap {
    ._g_home-page {
      width: 1920px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
