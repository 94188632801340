.loading{
  position: fixed;
  width: 100vw;
  height: 100vh;
  left:0;
  top:0;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  display: flex;
}
.loading_body{
  width: 60px;
}